<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.name"
        allow-clear
        label="搜索名称"
        v-bind="layout"
        placeholder="请输入文化点名称"
        @pressEnter="onSearch"
      />
      <BjSelect
        v-model="search.group_id"
        v-bind="layout"
        key-field="id"
        label="所属分组"
        inner-search
        placeholder="全部分组"
        value-field="id"
        label-field="name"
        show-all
        :options="groupData"
      />
      <BjValidationItem label="所属类型" v-bind="layout">
        <bj-cascader
          v-model="search.poc_type_id"
          :options="typeData"
          change-on-select
          expand-trigger="hover"
          :field-names="{
            label: 'name',
            value: 'id',
            children: 'children',
          }"
          :show-search="false"
          placeholder="请选择类型"
          :allow-clear="false"
          :load-data="onLoadData"
        />
      </BjValidationItem>
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        inner-search
        label="发布状态"
        placeholder="全部状态"
        value-field="id"
        show-all
        label-field="label"
        :options="exportStatus"
      />
      <BjSelect
        v-model="search.author_id"
        v-bind="layout"
        key-field="id"
        label="作者信息"
        placeholder="全部状态"
        value-field="id"
        show-all
        label-field="real_name"
        :options="authorData"
      />
    </BjSearch>
    <div :class="$style.container">
      <a-row :class="$style.header">
        <a-col span="4" :class="$style.title"> 文化点列表 </a-col>
        <a-col span="20" :class="$style.btns">
          <div :class="[$style.badge, recycleTotal === 0 ? $style.badgeno : null]">
            <pointRecycle v-permission="'resources.poc.manage-recycle.view'" :count="recycleTotal" @refresh="refresh" />
          </div>
          <displayMode v-model="active" :class="$style.mode" />
          <BjButton v-permission="'resources.poc.manage-create'" type="primary" @click="onAdd()">
            <i class="ri-add-line left" />
            新建文化点
          </BjButton>
        </a-col>
      </a-row>
      <Table
        v-if="!active"
        :loading="loading"
        :data="data"
        :group-data="groupData"
        @refresh="refresh"
        @onEdit="onEdit"
        @onEditor="onEditor"
        @onComment="onComment"
        @onDelete="onDelete"
        @onCopy="onCopy"
      />
      <Box
        v-else
        :data="data"
        :loading="loading"
        :group-data="groupData"
        @refresh="refresh"
        @onEdit="onEdit"
        @onEditor="onEditor"
        @onComment="onComment"
        @onDelete="onDelete"
        @onCopy="onCopy"
      />
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>
    <chooseMaterial :visible.sync="visible.choose" />
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'

import chooseMaterial from '@/components/chooseMaterial'
import displayMode from '@/components/displayMode'
import pointRecycle from '@/components/pointRecycle'
import { commonService, groupService, pointService } from '@/service'
import { exportStatus } from '@/utils/variable'

import Box from './Box.vue'
import Table from './Table.vue'

const cService = new commonService()

const gService = new groupService()
const service = new pointService()

export default {
  name: 'Home',
  components: {
    chooseMaterial,
    displayMode,
    pointRecycle,
    Table,
    Box,
  },
  data() {
    return {
      exportStatus,
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        search: false,
        choose: false,
      },
      search: {
        name: null,
        status: 0,
        group_id: '',
        poc_type_id: [''],
        author_id: '',
      },
      data: [],
      total: 0,
      page: 1,
      pageSize: 20,
      groupData: [
        {
          id: '',
          name: '全部分组',
        },
      ],
      typeData: [
        {
          id: '',
          name: '全部类型',
        },
      ],
      authorData: [
        {
          id: '',
          real_name: '全部作者',
        },
      ],
      recycleTotal: 0,
      active: true,
      author: false,
    }
  },
  created() {
    if (this.$route.params.author_id) {
      this.search.author_id = this.$route.params.author_id
    }
    this.getGroup()
    this.getType()
    this.getRecycleTotal()
    this.getList()
    this.getAuthor()
    this.getAuthorState()
  },
  methods: {
    async getAuthorState() {
      const { data } = await cService.authorCheck()
      this.author = data
    },
    async getRecycleTotal() {
      const { data } = await service.recycleTotal()
      this.recycleTotal = data
    },
    async getGroup() {
      try {
        const { data } = await gService.list({
          no_page: 1,
        })
        this.groupData = this.groupData.concat(data)
      } catch (e) {}
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.list({
          ...this.search,
          page: this.page,
          page_size: this.pageSize,
          group_id: this.search.group_id === 0 ? '' : this.search.group_id,
          poc_type_id: this.search.poc_type_id.length
            ? this.search.poc_type_id[this.search.poc_type_id.length - 1]
            : '',
        })
        this.data = data.record
        this.total = data.total
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getAuthor() {
      try {
        const { data } = await service.getAuthor({
          no_page: 1,
        })
        this.authorData = this.authorData.concat(data)
      } catch (e) {}
    },
    async onLoadData(e) {
      const targetOption = e[e.length - 1]
      targetOption.loading = true
      const { data } = await service.getType({
        parent_id: targetOption.id,
        is_simple: 1,
      })
      targetOption.loading = false
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      targetOption.children = data
      this.typeData = [...this.typeData]
    },
    async getType() {
      const { data } = await service.getType({
        parent_id: null,
        is_simple: 1, // 是否获取简单数据0-获取完整带目录与属性的数据1-获取不带目录与属性的数据
      })
      data.map(item => {
        if (item.has_children) {
          item.isLeaf = false
        }
      })
      this.typeData = this.typeData.concat(data)
    },
    onAdd() {
      if (!this.author) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this
        this.$confirm({
          title: '账号无作者信息',
          content: '当前登录管理员账号无作者信息，需要新建作者信息或通过超级管理员进行分配。',
          onOk() {
            _this.$router.push({
              name: 'systemInfo',
              query: {
                page: 'author',
              },
            })
          },
          okText: '新建作者信息',
          cancelText: '取消',
          onCancel() {
            Modal.destroyAll()
          },
        })
        return
      }
      this.$router.push({
        name: 'resourcesPointAdd',
        params: { id: 'add' },
      })
    },
    refresh() {
      this.getList()
      this.getRecycleTotal()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    init() {
      this.search = {
        name: null,
        status: 0,
        group_id: '',
        poc_type_id: [''],
        type: '',
        author_id: '',
      }
      this.getList()
    },
    onEdit(item) {
      if (!this.author) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        let _this = this
        this.$confirm({
          title: '账号无作者信息',
          content: '当前登录管理员账号无作者信息，需要新建作者信息或通过超级管理员进行分配。',
          onOk() {
            _this.$router.push({
              name: 'systemInfo',
              query: {
                page: 'author',
              },
            })
          },
          okText: '新建作者信息',
          cancelText: '取消',
          onCancel() {
            Modal.destroyAll()
          },
        })
        return
      }
      this.$router.push({
        name: 'resourcesPointAdd',
        params: { id: item.id },
      })
    },
    // index poc template
    onEditor(item) {
      this.$store.commit('EDITOR', {
        page_type: 'poc',
        poc_id: item.id,
      })
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'editor',
      })
    },
    onComment(item) {
      this.$store.commit('COMMENT', {
        poc_id: item.id,
        keyword: item.name,
      })
      this.$router.push({
        name: 'resourcesComment',
      })
    },
    async onDelete(item) {
      try {
        await service.pointDelete({ id: item.id })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.refresh()
      }
    },
    async onCopy(item) {
      try {
        await service.pointCopy({
          poc_id: item.id,
        })
        this.$message.success('复制成功')
      } catch (e) {
      } finally {
        this.refresh()
      }
    },
  },
}
</script>

<style lang="less" module>
.home {
  .container {
    margin-top: 20px;
    padding: 20px;
    background: #fff;

    .title {
      color: #000;
      font-size: 16px;
    }

    .btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .mode {
        width: 93px;
        height: 20px;
        margin: 0 20px;
        text-align: right;
        border-right: 1px solid #eee;
        border-left: 1px solid #eee;

        i {
          position: relative;
          top: -5px;
        }
      }

      .badge {
        width: 105px;
        height: 30px;
        color: #000;
        line-height: 30px;
        cursor: pointer;

        i {
          position: relative;
          top: 2px;
          left: -2px;
          color: #000;
          font-size: 20px;
        }
      }

      .badgeno {
        width: 70px;
      }
    }
  }

  .header {
    padding: 0 0 20px 0;
  }
}
</style>
