<template>
  <bj-modal
    :class="$style.choose"
    :visible="visible"
    :width="1200"
    :body-style="{ height: '600px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
    title="选择素材"
    @cancel="cancel"
  >
    <a-tabs v-model="type" :class="$style.tab" @change="change">
      <a-tab-pane key="image" tab="图片" :disabled="!allow.includes('image')">
        <chooseMaterialDetail ref="image" :multiple="multiple" type="image" @select="selectNum" />
      </a-tab-pane>
      <a-tab-pane key="audio" tab="音频" :disabled="!allow.includes('audio')">
        <chooseMaterialDetail ref="audio" :multiple="multiple" type="audio" @select="selectNum" />
      </a-tab-pane>
      <a-tab-pane key="video" tab="视频" :disabled="!allow.includes('video')">
        <chooseMaterialDetail ref="video" :multiple="multiple" type="video" @select="selectNum" />
      </a-tab-pane>
      <div slot="tabBarExtraContent" :class="$style.btns">
        <BjLink type="primary" @click="toCenter()">前往素材中心</BjLink>
      </div>
    </a-tabs>
    <template #footer>
      <a-row>
        <a-col class="text-left" span="12">
          <span :class="$style.select">{{ select }}</span
          >个素材已被选择
        </a-col>
        <a-col class="text-right" span="12">
          <BjButton @click="cancel()">取消</BjButton>
          <BjButton type="primary" @click="getChoose()">确定</BjButton>
        </a-col>
      </a-row>
    </template>
  </bj-modal>
</template>

<script>
import chooseMaterialDetail from './chooseMaterialDetail'

export default {
  name: 'chooseMaterial',
  components: {
    chooseMaterialDetail,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    allow: {
      type: Array,
      default: () => ['image', 'video', 'audio'],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    active: {
      type: String,
      default: 'image',
    },
  },
  data() {
    return {
      type: 'image',
      select: 0,
      data: [],
    }
  },
  computed: {
    refreshmaterial: {
      get() {
        return this.$store.state.refreshmaterial
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.type = this.active ? this.active : 'image'
        this.$nextTick(() => {
          this.$refs[this.type].refresh()
        })
      } else {
        this.change()
        this.select = 0
      }
    },
    refreshmaterial() {
      this.$refs[this.type] && this.$refs[this.type].refresh()
    },
  },
  methods: {
    selectNum(num, data) {
      this.select = num
      this.data = data
    },
    change() {
      this.select = 0
      if (this.$refs.image) {
        this.$refs.image.selectedRowKeys = []
      }
      if (this.$refs.video) {
        this.$refs.video.selectedRowKeys = []
      }
      if (this.$refs.audio) {
        this.$refs.audio.selectedRowKeys = []
      }
    },
    getChoose() {
      this.data.map(item => {
        item.type = this.type
      })
      if (!this.data.length) {
        this.$message.warning('请选择素材')
        return
      }
      this.$emit('getChoose', this.data)
      // 抛出数据
      this.$emit('update:visible', false)
    },
    cancel() {
      this.$emit('update:visible', false)
    },
    toCenter() {
      this.$router.push({
        name: 'contentMaterial',
      })
    },
  },
}
</script>

<style lang="less" module>
.choose {
  .tab {
    overflow-x: hidden;
  }

  :global {
    .ant-tabs-nav-wrap {
      height: 44px;
      color: #000;
    }
  }

  .select {
    // position: relative;
    // top: 4px;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 2px;
    color: #fff;
    line-height: 20px;
    text-align: center;
    background: @primary-color;
    border-radius: 10px;
  }
}
</style>
