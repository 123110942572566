<template>
  <div :class="$style.detail">
    <a-row>
      <a-col span="16">
        <bj-input v-model="search.keyword" class="w-150 mr-10" placeholder="请输入关键词" @pressEnter="onSearch">
          <i slot="prefix" class="ri-search-line" />
        </bj-input>
        <bj-select
          v-model="search.sort"
          class="w-150 mr-10"
          key-field="id"
          placeholder="请选择"
          value-field="id"
          label-field="label"
          show-all
          :options="sortOption"
        />
        <bj-button class="btn-default" @click="onSearch">搜索</bj-button>
      </a-col>
      <a-col span="8" :class="$style.btns">
        <i :class="$style.refresh" class="ri-refresh-line" @click="refresh()" />
        <span :class="$style.cut" />
        <bj-button type="primary" @click="update()">
          <i :class="$style.icon" class="ri-upload-line" />
          上传{{ type | typeName }}
        </bj-button>
      </a-col>
    </a-row>
    <div :class="$style.box">
      <div :class="$style.left">
        <group :title-show="false" :edit="false" :type="type" :active="search.group_id" @change="groupChange" />
      </div>
      <div :class="$style.right">
        <a-table :columns="columns" :loading="loading" :data-source="data" :pagination="false" row-key="id">
          <template #choose="item">
            <div :class="$style.choose">
              <a-checkbox
                v-if="multiple"
                :checked="selectedRowKeys.indexOf(item.id) !== -1"
                @change="onChange(item.id, item)"
              />
              <a-radio v-else :checked="selectedRowKeys.indexOf(item.id) !== -1" @click="onChange(item.id)" />
            </div>
          </template>
          <template #cover="item">
            <div :class="$style.coverBox">
              <div :class="$style.cover">
                <img :src="item.cover" />
                <div v-if="item.status === 2 || item.status === 3 || item.status === 4" :class="$style.state">
                  <p>{{ item.status | state }}</p>
                </div>
              </div>
              <div :class="$style.name">
                <p :class="$style.filename" class="ellipsis-width" :title="item.filename + item.ext">
                  {{ item.filename }}{{ item.ext }}
                </p>
                <p :class="$style.duration">
                  <span v-if="type === 'image'">{{ item.width }} * {{ item.height }}</span>
                  <span v-else>{{ item.duration }}</span>
                </p>
              </div>
            </div>
          </template>
        </a-table>
        <div class="pagination">
          <a-pagination
            :current="page"
            show-size-changer
            :show-total="total => `共 ${total} 条`"
            :page-size.sync="pageSize"
            :total="total"
            @showSizeChange="onShowSizeChange"
            @change="onPageChange"
          />
        </div>
      </div>
    </div>
    <uploadModal :type="type" :visible.sync="visible" :default-group="uploadGroup" />
  </div>
</template>

<script>
import uploadModal from '@/components/uploadModal'
import { materialService } from '@/service'
import { sortOption } from '@/utils/variable'
import group from '@/views/content/material/group.vue'

const service = new materialService()

export default {
  name: 'chooseMaterialDetail',
  components: {
    group,
    uploadModal,
  },
  filters: {
    typeName(val) {
      switch (val) {
        case 'image':
          return '图片'
        case 'video':
          return '视频'
        case 'audio':
          return '音频'
      }
    },
    state(val) {
      switch (val) {
        case 0:
          return '禁用'
        case 1:
          return '正常'
        case 2:
          return '待转码'
        case 3:
          return '转码中'
        case 4:
          return '上传中'
      }
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: {
        keyword: null,
        sort: 'time_desc',
        group_id: 'all',
      },
      selectedRowKeys: [],
      selectedData: [],
      sortOption,
      visible: false,
      data: [],
      page: 1,
      pageSize: 20,
      total: 0,
      loading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '',
          width: 80,
          scopedSlots: {
            customRender: 'choose',
          },
        },
        {
          title: '图片',
          width: 300,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '大小', dataIndex: 'file_size_format' },
        {
          title: '上传时间',
          dataIndex: 'created_at',
        },
      ]
    },
    uploadGroup() {
      return this.search.group_id === 'all' ||
        this.search.group_id === 'last_time' ||
        this.search.group_id === 'default'
        ? 'default'
        : this.search.group_id
    },
  },
  watch: {
    selectedRowKeys(val) {
      if (val.length) {
        this.selectedData = []
        // this.data.map(item => {
        //   if (this.selectedRowKeys.indexOf(item.id) !== -1) {
        //     this.selectedData.push(item)
        //   }
        // })
        // 处理下编辑器选择顺序  按正向选择顺序
        this.selectedRowKeys.map(item => {
          this.data.map(cItem => {
            if (cItem.id === item) {
              this.selectedData.push(cItem)
            }
          })
        })
      }
      this.$emit('select', val.length, this.selectedData)
    },
  },
  created() {
    this.refresh()
  },
  methods: {
    groupChange(id) {
      this.search.group_id = id
      this.page = 1
      this.refresh()
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChange(id, item) {
      if (this.multiple) {
        if (this.selectedRowKeys.indexOf(id) === -1) {
          this.selectedRowKeys.push(id)
        } else {
          this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== id)
        }
      } else {
        this.selectedRowKeys = []
        this.selectedRowKeys.push(id)
      }
    },
    onSearch() {
      this.page = 1
      this.refresh()
    },
    update() {
      this.visible = true
    },
    onPageChange(page) {
      this.page = page
      this.refresh()
    },
    onShowSizeChange() {
      this.page = 1
      this.refresh()
    },
    async refresh() {
      try {
        this.loading = true
        const payload = { material_type: this.type, ...this.search, page: this.page, page_size: this.pageSize }
        const { data } = await service.lists(payload)
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.detail {
  .box {
    display: flex;
    height: 100%;
  }

  .left {
    width: 220px;
  }

  .right {
    flex: 1;
    padding: 0 10px 10px;
  }

  .btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;

    .icon {
      color: #fff;
      font-size: 18px;
    }

    .cut {
      display: inline-block;
      height: 20px;
      margin: 0 20px;
      border-left: 1px solid #eee;
    }

    .refresh {
      color: #000;
      font-size: 20px;
    }
  }

  .cover-box {
    display: flex;
    width: 250px;
  }

  .cover {
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }

    .state {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      font-size: 12px;
      line-height: 80px;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .name {
    flex: 1;
    padding: 15px;

    .filename {
      color: #000;
      font-size: 14px;
    }

    .duration {
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .choose {
    padding-left: 30px;
  }
}
</style>
