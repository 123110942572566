<template>
  <div :class="$style.recycle" @click="onClick()">
    <a-badge
      :count="count"
      :number-style="{
        backgroundColor: '#1470df',
        color: '#fff',
      }"
      :offset="[22, 12]"
    >
      <i class="ri-delete-bin-4-line" />
      回收站
    </a-badge>
    <bj-modal
      title="回收站"
      :visible="visible"
      :width="1000"
      :body-style="{ height: '600px', 'max-height': '600px', position: 'relative', 'overflow-y': 'auto' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <div class="clear-btn">
          <BjButton
            v-permission="'resources.poc.manage-recycle.delete'"
            :loading="btnLoading"
            type="primary"
            @click="onClear()"
            >全部清空</BjButton
          >
          <p class="tip">删除的内容会在回收站保留 14 天，之后将被系统彻底删除。</p>
        </div>
        <a-spin v-if="loading" :class="$style.spin" />
        <div v-else>
          <a-table :columns="columns" :data-source="data" :pagination="false" row-key="id">
            <template #cover="item">
              <div :class="$style.box">
                <div :class="$style.cover" @click="onPreview(item)">
                  <img :src="item.cover.cover" />
                </div>
              </div>
            </template>
          </a-table>
          <div class="pagination">
            <a-pagination
              v-model="page"
              show-size-changer
              :show-total="total => `共 ${total} 条`"
              :page-size.sync="pageSize"
              :total="total"
              @showSizeChange="onShowSizeChange"
              @change="onPageChange"
            />
          </div>
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import { pointService } from '@/service'

const service = new pointService()

export default {
  name: 'pointRecycle',
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      page: 1,
      pageSize: 20,
      total: 0,
      data: [],
      loading: false,
      btnLoading: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '文化点封面',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '文化点名称',
          dataIndex: 'name',
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '管理',
          customRender: item => [
            <bj-link
              type='primary'
              v-permission="'resources.poc.manage-recycle.recover'"
              onClick={() => this.onRecovery(item.id)}
            >
              恢复
            </bj-link>,
            <a-popconfirm title='确定要彻底删除吗？删除之后就不能再次找回。' onConfirm={() => this.onDelete(item.id)}>
              <bj-link v-permission='resources.poc.manage-recycle.delete' type='danger'>
                彻底删除
              </bj-link>
            </a-popconfirm>,
          ],
        },
      ]
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getList()
      }
    },
  },
  methods: {
    onPreview(item) {
      let tmp = this.data.map(item => ({
        type: item.cover.type,
        cover: item.cover.cover,
      }))
      this.BjPreview(tmp, item.cover.cover)
    },
    onClick() {
      this.visible = true
    },
    onClear() {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let _this = this
      this.$confirm({
        content: '确定要清空全部回收站数据吗？清空之后就不能再次找回。',
        onOk() {
          _this.clear()
        },
      })
    },
    async onRecovery(id) {
      try {
        await service.recycleRecover({
          id: id,
        })
        this.$message.success('恢复成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.getList()
        this.$emit('refresh')
      }
    },
    async onDelete(id) {
      try {
        await service.recycleDelete({
          id: id,
        })
        this.$message.success('删除成功')
      } catch (e) {
      } finally {
        this.page = 1
        this.getList()
        this.$emit('refresh')
      }
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async clear() {
      try {
        this.btnLoading = true
        await service.recycleClear()
        this.$message.success('清空成功')
      } catch (e) {
      } finally {
        this.btnLoading = false
        this.page = 1
        this.getList()
        this.$emit('refresh')
      }
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.recycleList({
          page: this.page,
          page_size: this.pageSize,
        })
        this.total = data.total
        this.data = data.record
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    handleOk() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.recycle {
  display: inline-block;

  i {
    position: relative;
    top: 4px;
    color: #000;
    font-size: 20px;
  }
}

.modal {
  .box {
    display: flex;
  }

  .cover {
    position: relative;
    width: 80px;
    height: 53px;
    text-align: center;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 6px;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
