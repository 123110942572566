<template>
  <div :class="$style.mode">
    <i
      class="ri-function-line"
      :class="[active ? $style.active : $style.default, $style.icon]"
      @click="$emit('change', true)"
    />
    <i class="ri-menu-line mr-20" :class="!active ? $style.active : $style.default" @click="$emit('change', false)" />
  </div>
</template>

<script>
export default {
  name: 'displayMode',
  model: {
    prop: 'active',
    event: 'change',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="less" module>
.mode {
  display: inline-block;
  text-align: left;

  .active {
    color: #000;
    font-size: 20px;
  }

  .default {
    color: #bbb;
    font-size: 20px;
    cursor: pointer;
  }

  .icon {
    margin-right: 12px;
  }
}
</style>
