<template>
  <div :class="$style.table">
    <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
      <template #cover="item">
        <div :class="$style.cover">
          <img :src="item.cover.cover" />
        </div>
      </template>
      <template #status="item">
        <BjTag :type="item.status ? 'primary' : 'default'">{{ item.status === 1 ? '已发布' : '未发布' }}</BjTag>
      </template>
      <template #action="item">
        <!-- <a-popover> -->
        <!-- <template slot="content">
            <img :class="$style.qr" :src="item.qr_code" />
          </template>
        </a-popover> -->
        <bj-link type="primary" @click="onPreview(item)"> 预览 </bj-link>
        <bj-link type="primary" @click="onEditor(item)"> 主页 </bj-link>
        <bj-link type="primary" @click="onComment(item)"> 评论 </bj-link>
        <a-dropdown :trigger="['click']">
          <bj-link class="ant-dropdown-link" :trigger="['click']" type="primary">
            更多<i class="ri-arrow-down-s-line more-drop"
          /></bj-link>
          <a-menu slot="overlay" class="list-drop">
            <a-menu-item>
              <bj-link v-permission="'resources.poc.manage-edit'" @click="onEdit(item)">
                <i class="ri-edit-line" />
                编辑
              </bj-link>
            </a-menu-item>
            <a-menu-item>
              <bj-link v-permission="'resources.poc.manage-edit'" @click="onState(item)">
                <i class="ri-gradienter-line" />
                状态
              </bj-link>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm title="确定要复制文化点吗？" placement="topLeft" @confirm="onCopy(item)">
                <bj-link v-permission="'resources.poc.manage-edit'">
                  <i class="ri-file-copy-line" />
                  复制
                </bj-link>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-item>
              <bj-link v-permission="'resources.poc.manage-edit'" @click="onMove(item)">
                <i class="ri-input-cursor-move" />
                移动
              </bj-link>
            </a-menu-item>
            <a-menu-item>
              <a-popconfirm
                title="确定要删除文化点吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
                placement="topLeft"
                @confirm="onDelete(item)"
              >
                <bj-link v-permission="'resources.poc.manage-delete'">
                  <i class="ri-delete-bin-7-line" />
                  删除
                </bj-link>
              </a-popconfirm>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </a-table>
    <bj-modal title="文化点状态" :visible="visible.state" @ok="handleOk" @cancel="handleCancel">
      <BjForm>
        <BjValidationItem
          label="设置状态"
          rules="required"
          :label-col="layout.labelCol"
          :wrapper-col="layout.wrapperCol"
          label-align="left"
        >
          <a-radio-group v-model="releaseStatus" name="radioGroup">
            <a-radio :value="1"> 已发布 </a-radio>
            <a-radio :value="0"> 未发布 </a-radio>
          </a-radio-group>
        </BjValidationItem>
      </BjForm>
    </bj-modal>
    <bj-modal title="移动分组" :visible="visible.group" @ok="moveOk" @cancel="moveCancel">
      <BjForm>
        <BjSelect
          v-model="group_id"
          v-bind="layout"
          rules="required"
          key-field="id"
          label="选择分组"
          inner-search
          placeholder="请选择需要移动至哪个分组"
          value-field="id"
          label-field="name"
          show-all
          :options="showGroupData"
        />
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import { pointService } from '@/service'
import { status } from '@/utils/variable'

const service = new pointService()

export default {
  name: 'Table',
  filters: {
    statusName(val) {
      return status[val]
    },
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    groupData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        state: false,
        group: false,
      },
      releaseStatus: 1,
      id: null,
      group_id: [],
    }
  },
  computed: {
    ...mapMutations(['EDITOR']),
    showGroupData() {
      return this.groupData.length ? this.groupData.slice(1, this.groupData.length - 1) : []
    },
    columns() {
      return [
        {
          title: '文化点封面',
          width: 200,
          scopedSlots: {
            customRender: 'cover',
          },
        },
        { title: '文化点名称', dataIndex: 'name' },
        {
          title: '所属分组',
          customRender: item => item.group_name || '默认分组',
        },
        {
          title: '所属类型',
          customRender: item => item.poc_type_name || '-',
        },
        { title: '添加时间', dataIndex: 'created_at' },
        {
          title: '发布状态',
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: '管理',
          width: 180,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  methods: {
    onEdit(item) {
      this.$emit('onEdit', item)
    },
    // index poc template
    onEditor(item) {
      this.$emit('onEditor', item)
    },
    onComment(item) {
      this.$emit('onComment', item)
    },
    onDelete(item) {
      this.$emit('onDelete', item)
    },
    onState(item) {
      this.visible.state = true
      this.id = item.id
      this.releaseStatus = item.status
    },
    onMove(item) {
      this.visible.group = true
      this.id = item.id
    },
    onCopy(item) {
      this.$emit('onCopy', item)
    },
    async handleOk() {
      try {
        await service.pointStatus({
          id: this.id,
          status: this.releaseStatus,
        })
        this.$message.success('修改成功')
        this.visible.state = false
      } catch (e) {
      } finally {
        this.$emit('refresh')
      }
    },
    handleCancel() {
      this.visible.state = false
    },
    async moveOk() {
      try {
        await service.pointMove({
          poc_id: this.id,
          group_id: this.group_id,
        })
        this.$message.success('修改成功')
        this.visible.group = false
        this.group_id = []
        this.$emit('refresh')
      } catch (e) {}
    },
    moveCancel() {
      this.visible.group = false
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'poc',
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.table {
  :global {
    .ant-popover {
      min-width: 80px;
    }
  }

  .box {
    display: flex;
    width: 250px;
  }

  .cover {
    position: relative;
    width: 80px;
    height: 80px;
    text-align: center;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 100%;
      height: 53px;
      border-radius: 6px;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
  }

  .name {
    flex: 1;
    padding: 15px;

    .filename {
      color: #000;
      font-size: 14px;
    }

    .duration {
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .foot {
    margin-top: 100px;
    color: #000;
  }
}

.qr {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
