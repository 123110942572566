<template>
  <div :class="$style.box">
    <BjBox v-if="!loading" ref="box" :scale="1.5" :lose="0">
      <div v-for="item in data" :key="item.id" :class="$style.item">
        <div :class="$style.cover">
          <img :src="item.cover.cover" />
        </div>
        <div v-if="item.poc_type_name" class="ellipsis-width" :title="item.poc_type_name" :class="$style.tip">
          {{ item.poc_type_name }}
        </div>
        <div :class="$style.info">
          <BjTag v-if="!item.status" :class="$style.title" tooltip="未发布">
            <p class="ellipsis-width" :class="$style.name">{{ item.name }}</p>
          </BjTag>
          <p v-else class="ellipsis-width" :class="$style.title">{{ item.name }}</p>
          <p :class="$style.group">
            {{ item.group_name || '默认分组' }}
          </p>
          <div :class="$style.btns">
            <!-- <a-popover>
              <template slot="content">
                <img :class="$style.qr" :src="item.qr_code" />
              </template>
              <i class="ri-qr-code-line" />
            </a-popover> -->
            <a-tooltip placement="bottom">
              <template slot="title">
                <span>预览</span>
              </template>
              <i class="ri-eye-line" @click="onPreview(item)" />
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template slot="title">
                <span>文化主页</span>
              </template>
              <i v-permission="'resources.poc.manage-edit'" class="ri-article-line" @click="onEditor(item)" />
            </a-tooltip>
            <a-tooltip placement="bottom">
              <template slot="title">
                <span>评论</span>
              </template>
              <i class="ri-message-3-line" @click="onComment(item)" />
            </a-tooltip>
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <i class="ri-more-fill" />
              <a-menu slot="overlay" class="list-drop">
                <a-menu-item>
                  <bj-link v-permission="'resources.poc.manage-edit'" @click="onEdit(item)">
                    <i class="ri-edit-line" />
                    编辑
                  </bj-link>
                </a-menu-item>
                <a-menu-item>
                  <bj-link v-permission="'resources.poc.manage-edit'" @click="onState(item)">
                    <i class="ri-gradienter-line" />
                    状态
                  </bj-link>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm title="确定要复制文化点吗？" placement="topLeft" @confirm="onCopy(item)">
                    <bj-link v-permission="'resources.poc.manage-edit'">
                      <i class="ri-file-copy-line" />
                      复制
                    </bj-link>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item>
                  <bj-link v-permission="'resources.poc.manage-edit'" @click="onMove(item)">
                    <i class="ri-input-cursor-move" />
                    移动
                  </bj-link>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                    title="确定要删除文化点吗？删除之后会在回收站保留 14 天之后将被系统彻底删除。"
                    placement="topLeft"
                    @confirm="onDelete(item)"
                  >
                    <bj-link v-permission="'resources.poc.manage-delete'">
                      <i class="ri-delete-bin-7-line" />
                      删除
                    </bj-link>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </div>
    </BjBox>
    <div v-else :class="$style.loading">
      <a-spin />
    </div>
    <div v-if="!data.length && !loading" class="nodata">
      <img :src="oss + '/manage/media/state/no_data.png'" alt="" />
      <p>暂无数据</p>
    </div>
    <bj-modal title="文化点状态" :visible="visible.state" @ok="handleOk" @cancel="handleCancel">
      <BjForm>
        <BjValidationItem
          label="设置状态"
          rules="required"
          :label-col="layout.labelCol"
          :wrapper-col="layout.wrapperCol"
          label-align="left"
        >
          <a-radio-group v-model="releaseStatus" name="radioGroup">
            <a-radio :value="1"> 已发布 </a-radio>
            <a-radio :value="0"> 未发布 </a-radio>
          </a-radio-group>
        </BjValidationItem>
      </BjForm>
    </bj-modal>
    <bj-modal title="移动分组" :visible="visible.group" @ok="moveOk" @cancel="moveCancel">
      <BjForm>
        <BjSelect
          v-model="group_id"
          v-bind="layout"
          rules="required"
          key-field="id"
          label="选择分组"
          inner-search
          placeholder="请选择需要移动至哪个分组"
          value-field="id"
          label-field="name"
          show-all
          :options="showGroupData"
        />
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import { pointService } from '@/service'

const service = new pointService()

export default {
  name: 'Box',
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    groupData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      visible: {
        state: false,
        group: false,
      },
      releaseStatus: 1,
      id: null,
      group_id: [],
    }
  },
  computed: {
    showGroupData() {
      return this.groupData.length ? this.groupData.slice(1, this.groupData.length - 1) : []
    },
  },
  watch: {
    data() {
      this.$nextTick(() => {
        this.$refs.box.deal()
      })
    },
  },
  methods: {
    onEdit(item) {
      this.$emit('onEdit', item)
    },
    onCopy(item) {
      this.$emit('onCopy', item)
    },
    // index poc template
    onEditor(item) {
      this.$emit('onEditor', item)
    },
    onComment(item) {
      this.$emit('onComment', item)
    },
    onDelete(item) {
      this.$emit('onDelete', item)
    },
    onState(item) {
      this.visible.state = true
      this.id = item.id
      this.releaseStatus = item.status
    },
    onMove(item) {
      this.visible.group = true
      this.id = item.id
    },
    async handleOk() {
      try {
        await service.pointStatus({
          id: this.id,
          status: this.releaseStatus,
        })
        this.$message.success('修改成功')
        this.visible.state = false
      } catch (e) {
      } finally {
        this.$emit('refresh')
      }
    },
    handleCancel() {
      this.visible.state = false
    },
    async moveOk() {
      try {
        await service.pointMove({
          poc_id: this.id,
          group_id: this.group_id,
        })
        this.group_id = []
        this.$message.success('修改成功')
        this.visible.group = false
        this.group_id = []
        this.$emit('refresh')
      } catch (e) {}
    },
    moveCancel() {
      this.visible.group = false
    },
    onPreview(item) {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'poc',
          id: item.id,
        },
      })
    },
  },
}
</script>

<style lang="less" module>
.item {
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

  .cover {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0;

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      user-select: none;
      object-fit: cover;
    }
  }

  .tip {
    position: absolute;
    top: 30px;
    left: 10px;
    height: 24px;
    padding: 0 10px;
    color: #fff;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    background: @primary-color;
    border-radius: 0 6px 6px 0;
    max-width: 120px;
  }

  .info {
    height: 118px;
    padding: 12px 12px 0 12px;

    .title {
      margin-bottom: 6px;
      color: #000;
      font-size: 16px;
    }

    .group {
      color: #5c5c5c;
      font-size: 12px;
    }

    .name {
      margin: 0;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      margin: 16px 10px 0;
      color: #000;
      font-size: 18px;

      i:hover {
        color: @primary-color;
      }
    }
  }
}

.box {
  position: relative;
}

.loading {
  width: 100%;
  height: 400px;
  padding-top: 200px;
  text-align: center;
  background: rgba(256, 256, 256, 0.4);
}

.qr {
  width: 80px;
  height: 80px;
  object-fit: cover;
}
</style>
